header {

  position: sticky;
  width: 100%;
  height: 104px;

  background: #FFFFFF;
}

.header-top {
  height: 44px;
}

.header-bottom {
  display: flex;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.urlMenu {
  text-align: left;
}

.imageProduct {
  flex: 1;
  justify-content: center;
  text-align: center;
}

.urlMenuIcon {
  margin-right: 68%;
}

.dancingWahle {
  margin-left: 20%;
}

.dancingTitle {
  font-weight: bold;
  font-size: 18px;
}

.xIco {
  margin-left: 68%;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  width: 160px;
  left: 20px;
  top: 94px;
  background: white;
  border: 1px solid #E6E8EB;
  box-sizing: border-box;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  vertical-align: middle;
}

.pin {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
}

.dropBlock {
  height: 1px;
  background: #E6E8EB;
}

.pinText {
  margin-left: 5%;
}

.parent {
  display: flex;
  width: 100%;
  height: 60px;
  background: #585858;
}
.child {
  flex: 1;
  background: #2E9AFE;
}

