.image-context {
  width: 100%;

  background: #F6F7F8;

  text-align: center;  
}

.DPIco {
  width: 100%;
  vertical-align: middle;     
}

.content-context {
  margin-top: 20px;
  text-align: left;
  margin-left: 20px;
}

.content-title {
  text-align: left;
  margin-left: 20px;
  margin-top: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.price {
  margin-left: 20px;
  margin-top: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.btn-group {
  float: left;
  margin-left: 20px;
  margin-top: 12px;
}

.industry {
  float: left;
  background: #111111;
  color: white;
  border-radius: 4px;
  margin-right: 8px;
  margin-top: 8px;
  height: 28px;
  text-align: center;
}

.test {
  vertical-align: middle;
  height: 16px;
  padding: 6px 8px;
  font-size: 13px;
}

.button {
  float: left;
  background: #F0F1F2;
  border-radius: 4px;
  margin: 8px;
  height: 28px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button-test {
  vertical-align: middle;
  height: 16px;
  padding: 6px 8px;
  line-height: 16px;
  font-size: 13px; 
}

.descript {
  clear: both;
  width: 334px;
  height: 48px;
  margin-top: 20px;
  margin-left: 20px;

  background: #F6F6F6;
  /* system/080 */

  border: 1px solid #E6E8EB;
  box-sizing: border-box;
  border-radius: 8px;
}

.descript_inner {
  height: 24px;
  margin-top: 11px;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
}

.descript_inner > span {
  color: #5138E5;
}

.product_introduce {
  margin-left: 20px;
}

.product_intro {
  float: left;
}

.product_info {
  float: left;
  width: 50%;
  height: 55px;
  font-size: 16px;
  text-align:center;
}

.default_info {
  float: left;
  width: 50%;
  height: 55px;
  font-size: 16px;
  text-align:center;
}

.review {
  float: left;
  width: 125px;
  height: 55px;
  font-size: 16px;
  text-align:center;
}

.product_info_text {
  text-align: center;
  line-height: 55px;
}

.default_info_text {
  text-align: center;
  line-height: 55px;
}

.review_text {
  text-align: center;
  line-height: 55px;
}

.bottomDiv {
  clear: both;
  position: fixed;
  z-index: 20;
  bottom: 0;
  width: 100%;
  height: 110px;
  background: #FFFFFF;
  text-align: center;
  margin: auto;
}

.btn_product {
  width: 90%;
  height: 60px;
  border-radius: 12px;
  background: #5138E5;
  color: white;
  margin: auto;
  margin-top: 5%;
}

.hrDiv {
  height: 1px;
  background: #F0F1F2;
  margin-top: 12px;
}

.info_table {
  margin-top: 8px;
  height: 56px;
}

#barDiv_product {
  height: 3px;
  background: #111111;
}

#barDiv_default {
  height: 1px;
  background: #F0F1F2;
}

#barDiv_review {
  height: 1px;
  background: #F0F1F2;
}

.FullRect {
  position: absolute;
  z-index: 20px;
  width: 44px;
  height: 48px;
  right: 5%;
}

.BottomRect {
  position: absolute;
  width: 44px;
  height: 8px;
  right: 5%;
  top: 144px;

  background: #3F7ECE;
}

.GenuineProductText {
  position: absolute;
  width: 24px;
  height: 16px;
  right: 30px;
  top: 118px;
}