.blockDiv {
  width: 100%;
  height: 12px;
  background: #F2F4F5;
}

.InfoHeader {
  width: 100%;
  height: 52px;
  background: #FFFFFF;
  text-align: center;
  margin-top: 2%;
}

.DropDownTrue {
  float: right;
  margin-top: 4%;
  margin-right: 5%;
  display: block;
}

.DropDownFalse {
  float: right;
  margin-top: 4%;
  margin-right: 5%;
  display: none;
}

.text {
  float: left;
  margin-left: 5%;
  margin-top: 2%;
}

.bottomBlock {
  height: 1px;
  background: #F0F1F2;
}

.productDiv {
  text-align: center;
}

.sellerDiv {
  text-align: center;
}

.footerBlock {
  width: 100%;
  height: 40px;
  background: #F2F4F5;
  margin-top: 5%;
}

.defaultTable {
  width: 90%;
  height: 216px;
  margin-left: 20px;
  border-collapse: collapse;
  
}

.tableTitle {
  background: #F2F4F5;
  width: 35%;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #E6E8EB;
  text-align: left;
}

.productName {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #E6E8EB;
  text-align: left;
}

.productType {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #E6E8EB;
  text-align: left;
}

.brandName {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #E6E8EB;
  text-align: left;
}

.capacity {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #E6E8EB;
  text-align: left;
}

.production {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #E6E8EB;
  text-align: left;
}

.sellerTable {
  width: 90%;
  height: 216px;
  margin-left: 20px;
  border-collapse: collapse;
}

.companyName {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #E6E8EB;
  text-align: left;
}

.companyAddress {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #E6E8EB;
  text-align: left;
}

.homepage {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #E6E8EB;
  text-align: left;
}

.customerCenter {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #E6E8EB;
  text-align: left;
}